<template>
	<div>
		<div class="content">
			<div class="order_tip">
				<div class="left">
					<div class="title">
						<div class="main" v-if="1">
							<i class="el-icon-success"></i>
							{{title}}
						</div>
						<div class="main" v-else>
							<i class="el-icon-error"></i>
							订单过期
						</div>
						<p>{{title}}，订单号：{{ order_id }}</p>
						<p>
							请您尽快完成支付，否则订单会自动取消！支付剩余时间：
							<span>{{ letTimes }}</span>
						</p>
					</div>
				</div>
				<div class="right">
					<!-- <img :src="code" /> -->
					<p>APP下载 (没上架，待审核)</p>
				</div>
			</div>
			<el-card class="box-card">
				<div slot="header" class="clearfix"><span>支付方式</span></div>
				<div class="text item">
					<el-radio border v-model="payTypeValue" :label="item.label" v-for="(item, index) in payType" :key="index" class="radioItem">
						<img v-if="index == 0" src="@assets/images/weixin.png" />
						<img v-else src="@assets/images/zhifubao.png" />
					</el-radio>
				</div>
			</el-card>
			<div class="submit_box">
				<div class="text">
					实付：
					<span>￥ {{ orderInfo.pay_price }}</span>
				</div>
				<div class="submit_btn" @click="payNow">立即付款</div>
			</div>
		</div>
		<!-- 订单支付的弹窗 -->
		<el-dialog :title="title" :visible.sync="payDialogVisible" width="30%" center>
			<div class="paycontent">
				<img src="@assets/images/pay.png" alt="" />
				<div>打开手机{{ title }}扫一扫，</div>
				<div class="paydot">
					剩余时间
					<span>{{ time }}</span>
					，过期后窗口自动关闭
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { orderDetail } from '@api/user';
export default {
	name: 'Index',
	components: {},
	data() {
		return {
			title:{},
			orderInfo: {},
			payDialogVisible: false,
			order_id: this.$route.query.orderId,
			// code: require('@/assets/images/appStore_' + this.$config.appvesion + '.png'),
			letTimes: null,
			time: 60,
			payType: [
				{
					label: '微信'
				},
				{
					label: '支付宝'
				}
			],
			payTypeValue: '微信'
		};
	},
	mounted() {
		let that = this;
		that.orderId = that.$route.query.orderId;
		that.countDown('top', 5400);
		that.getOrderInfo();
	},
	methods: {
		getOrderInfo() {
			let that = this;
			orderDetail(that.orderId).then(res => {
				that.orderInfo = res.data;
				that.title = '订单提交成功';
			});
		},
		countDown(direction, times) {
			let that = this;
			let timer = null;
			timer = setInterval(function() {
				let day = 0,
					hour = 0,
					minute = 0,
					second = 0; // 时间默认值
				if (times > 0) {
					day = Math.floor(times / (60 * 60 * 24));
					hour = Math.floor(times / (60 * 60)) - day * 24;
					minute = Math.floor(times / 60) - day * 24 * 60 - hour * 60;
					second = Math.floor(times) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;
				}
				if (day <= 9) day = '0' + day;
				if (hour <= 9) hour = '0' + hour;
				if (minute <= 9) minute = '0' + minute;
				if (second <= 9) second = '0' + second;
				if (direction == 'top') {
					that.letTimes = `${day !== '00' ? `${day}天:` : ''}${hour}小时：${minute}分钟：${second}秒`;
				} else {
					that.time = `${day !== '00' ? `${day}天:` : ''}${hour}小时：${minute}分钟：${second}秒`;
				}
				times--;
			}, 1000);
			if (times <= 0) {
				if (direction == 'top') {
					that.letTimes = '';
				} else {
					that.time = '';
				}
				clearInterval(timer);
			}
		},
		payNow() {
			let that = this;
			that.payDialogVisible = true;
			that.title = that.payTypeValue + '支付';
			if (that.time === 60) {
				that.countDown('bottom', 60);
			}
		}
	}
};
</script>

<style lang="less" scoped>
.el-header {
	padding: 0;
	height: auto !important;
	background: #fff;
}

.el-main {
	padding: 0;
	margin: 10px 0;
	background: #fff;
	.paycontent {
		font-size: 16px;
		text-align: center;
		img {
			width: 188px;
		}
		.paydot {
			margin-top: 60px;
			font-size: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				color: #f60;
			}
		}
	}
	.content {
		width: 1232px;
		margin: 16px auto;
		.order_tip {
			height: 240px;
			margin-bottom: 16px;
			padding: 32px 168px 32px 16px;
			background-color: #f8fbff;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.title {
				i {
					color: #f60;
				}
				div.main {
					font-size: 20px;
					margin-left: 8px;
					color: #f60;
				}
				p {
					height: 20px;
					line-height: 20px;
					font-size: 14px;
					margin-bottom: 4px;
					span {
						color: #f60;
					}
				}
			}
			.right {
				text-align: center;
				img {
					width: 128px;
				}
			}
		}
		.box-card {
			.item {
				display: flex;
				.radioItem {
					height: auto;
					display: flex;
					align-items: center;
					padding: 10px 20px;
					img {
						width: 144px;
					}
				}
			}
		}
		.submit_box {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.text {
				height: 35px;
				margin-top: 24px;
				margin-bottom: 16px;
				font-size: 14px;
				span {
					font-size: 26px;
					font-weight: 700;
					color: #f60;
				}
			}
			.submit_btn {
				width: 80px;
				height: 36px;
				padding: 0;
				line-height: 36px;
				text-align: center;
				font-size: 14px;
				background: #f60;
				color: #fff;
				border-radius: 4px;
				cursor: pointer;
			}
		}
	}
}

.el-footer {
	padding: 0;
	height: auto !important;
}
</style>
